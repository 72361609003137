import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import PropTypes from 'prop-types';

export const PrivateLayout = ({ children }) => {
  const { password } = useAuth();

  if (
    password !== process.env.SITE_PASSWORD &&
    password !== process.env.ONLINE_PASSWORD
  ) {
    return <Navigate to="/" replace />;
  }

  return children || <Outlet />;
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
};
