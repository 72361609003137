import React from 'react';
import { Text, View, SectionList, useWindowDimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import { useLocation } from 'react-router-dom';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useMediaQueries } from '../../hooks/useMediaQueries';

const WeddingParty = () => {
  const location = useLocation();
  const styles = useStyles();
  const width = useWindowDimensions().width;
  const { isMobile } = useMediaQueries();

  const sections = [
    {
      title: 'marriage godparents',
      data: [
        { male: 'Longstaff, Darren', female: 'Longstaff, Carlota' },
        { male: 'Pangilinan, Rene', female: 'Pangilinan, Estrellita' },
        { male: 'Guazon, Gerry', female: 'Guazon, Earl' },
        { male: 'Toledo, Roland', female: 'Toledo, Nympha' },
        { male: 'Gopez, Donald', female: 'Gopez, Madel' },
      ],
    },
    {
      title: 'bestman',
      data: ['Ruaya, Mykel Robin'],
    },
    {
      title: 'maid of honor',
      data: ['Regencia, Kristina'],
    },
    {
      title: 'secondary sponsors',
      data: [
        {
          type: 'Candle',
          male: 'Marilao, Arvin',
          female: `Samac, Arlou ${!isMobile ? 'Michelle' : ''}`,
        },
        {
          type: 'Veil',
          male: `Dadia, Paolo ${!isMobile ? 'Gilberto' : ''}`,
          female: `Sabio, Arianah ${!isMobile ? 'Cassandra' : ''}`,
        },
        {
          type: 'Cord',
          male: 'Bautista, Paulo',
          female: `Fronteras, Garlen ${!isMobile ? 'Mary' : ''}`,
        },
      ],
    },
    {
      title: 'bearers',
      data: [
        {
          type: 'Ring',
          person: 'Enriquez, Adrielle Rej',
        },
        {
          type: 'Bible',
          person: 'Bernardino, CJ',
        },
        {
          type: 'Coin',
          person: 'Enriquez, Rielle Ryche',
        },
      ],
    },
  ];

  const renderSectionHeader = ({ section: { title } }) => (
    <Text style={styles.title}>{title}</Text>
  );

  const renderItem = ({ item, section: { title } }) => {
    if (title === 'bestman' || title === 'maid of honor') {
      return <Text style={[styles.text, { alignSelf: 'center' }]}>{item}</Text>;
    } else if (title === 'secondary sponsors') {
      return (
        <View
          style={[styles.row, { width: isMobile ? width * 0.95 : width * 0.8 }]}
        >
          <View style={{ flex: 1, textAlign: 'right' }}>
            <Text style={styles.type}>{item.type}</Text>
          </View>
          <View style={[{ flex: 2, textAlign: 'center' }]}>
            <Text style={styles.text}>{item.male}</Text>
          </View>
          <View style={[{ flex: 2, textAlign: 'center' }]}>
            <Text style={styles.text}>{item.female}</Text>
          </View>
          {!isMobile && <View style={{ flex: 1 }} />}
        </View>
      );
    } else if (title === 'bearers') {
      return (
        <View
          style={[styles.row, { width: isMobile ? width * 0.95 : width * 0.8 }]}
        >
          <View style={{ flex: 1, textAlign: 'right' }}>
            <Text style={styles.type}>{item.type}</Text>
          </View>
          <View style={[{ flex: 4, textAlign: 'center' }]}>
            <Text style={styles.text}>{item.person}</Text>
          </View>
          <View style={{ flex: 1 }} />
        </View>
      );
    } else {
      return (
        <View
          style={[styles.row, { width: isMobile ? width * 0.95 : width * 0.8 }]}
        >
          {!isMobile && <View style={{ flex: 1 }} />}
          <View style={[{ flex: 2, textAlign: 'center' }]}>
            <Text style={styles.text}>{item.male}</Text>
          </View>
          <View style={[{ flex: 2, textAlign: 'center' }]}>
            <Text style={styles.text}>{item.female}</Text>
          </View>
          {!isMobile && <View style={{ flex: 1 }} />}
        </View>
      );
    }
  };

  const content = () => (
    <View style={[styles.container]}>
      <SectionList
        sections={sections}
        keyExtractor={(item, index) => index}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
      />
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
      marginBottom: 40,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 120,
      color: '#ebae16',
      marginHorizontal: 10,
      letterSpacing: 4,
      alignSelf: 'center',
      textAlign: 'center',
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      textAlign: 'center',
      marginBottom: 10,
    },
    type: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#ebae16',
      marginBottom: 10,
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 100,
      },
      text: {
        fontSize: 35,
        marginBottom: 30,
      },
      type: {
        fontSize: 35,
        marginBottom: 30,
      },
    },
  },
);

export default WeddingParty;
