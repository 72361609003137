import React, { useRef } from 'react';
import {
  Text,
  ScrollView,
  useWindowDimensions,
  Animated,
  View,
  Pressable,
} from 'react-native';
import Header from '../../../assets/images/header.png';
import Ampersand from '../../../assets/images/ampersand.png';

import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';

const H_MAX_HEIGHT = 350;
const H_MIN_HEIGHT = 130;
const H_SCROLL_DISTANCE = H_MAX_HEIGHT - H_MIN_HEIGHT;

const HeaderBar = ({ renderContent, path }) => {
  const { password } = useAuth();
  const navigate = useNavigate();
  const styles = useStyles();

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const ratio = width / 1600;

  const scrollOffsetY = useRef(new Animated.Value(0)).current;
  const headerScrollHeight = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [H_MAX_HEIGHT, H_MIN_HEIGHT],
    extrapolate: 'clamp',
  });
  const titleFontSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [60, 25],
    extrapolate: 'clamp',
  });
  const titleMarginSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [1, 4],
    extrapolate: 'clamp',
  });
  const subtitleFontSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [24, 14],
    extrapolate: 'clamp',
  });
  const ampersandSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [60, 28],
    extrapolate: 'clamp',
  });
  const ampersandMarginSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [5, 0],
    extrapolate: 'clamp',
  });
  const marginTopSize = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [120, 5],
    extrapolate: 'clamp',
  });
  const bgOpacity = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });
  const headerBackground = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: ['rgba(255,255,255, 0)', 'rgba(255,255,255, 1)'],
    extrapolate: 'clamp',
  });
  const tabMargin = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: [20, 0],
    extrapolate: 'clamp',
  });
  const shadow = scrollOffsetY.interpolate({
    inputRange: [0, H_SCROLL_DISTANCE],
    outputRange: ['rgba(225,225,225,0)', 'rgba(225,225,225,1)'],
    extrapolate: 'clamp',
  });

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={[(styles.container, { height, width })]}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollOffsetY } } }],
          { useNativeDriver: false },
        )}
        scrollEventThrottle={16}
      >
        <View style={{ paddingTop: H_MAX_HEIGHT }} />
        <Animated.Image
          style={[
            styles.image,
            {
              width,
              height: 900 * ratio,
              opacity: bgOpacity,
            },
          ]}
          source={{ uri: Header }}
        />
        {renderContent()}
      </ScrollView>
      <Animated.View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: headerScrollHeight,
          width,
          backgroundColor: headerBackground,
        }}
        pointerEvents="none"
      >
        <View style={styles.container}>
          <Animated.View style={[styles.row, { marginTop: marginTopSize }]}>
            <Animated.Text style={[styles.title, { fontSize: titleFontSize }]}>
              DREI
            </Animated.Text>
            <Animated.Image
              style={[
                styles.ampersand,
                {
                  width: ampersandSize,
                  marginTop: ampersandMarginSize,
                  marginRight: ampersandMarginSize,
                },
              ]}
              source={{ uri: Ampersand }}
            />
            <Animated.Text
              style={[
                styles.title,
                { fontSize: titleFontSize, marginLeft: titleMarginSize },
              ]}
            >
              INRI
            </Animated.Text>
          </Animated.View>
          <Animated.Text
            style={[styles.subtitle, { fontSize: subtitleFontSize }]}
          >
            {password === process.env.SITE_PASSWORD
              ? 'July 2023'
              : 'Online Wedding'}
          </Animated.Text>
          <Animated.View
            style={[
              styles.row,
              {
                marginTop: tabMargin,
                width,
                justifyContent: 'space-evenly',
                borderBottomColor: shadow,
                borderBottomWidth: 1,
              },
            ]}
            pointerEvents="auto"
          >
            <Pressable onPress={() => navigate('/home')}>
              <Text style={[styles.tab, path === '/home' && styles.active]}>
                HOME
              </Text>
            </Pressable>
            <Pressable onPress={() => navigate('/our-story')}>
              <Text
                style={[styles.tab, path === '/our-story' && styles.active]}
              >
                OUR STORY
              </Text>
            </Pressable>
            <Pressable onPress={() => navigate('/wedding-party')}>
              <Text
                style={[styles.tab, path === '/wedding-party' && styles.active]}
              >
                WEDDING PARTY
              </Text>
            </Pressable>
            {password === process.env.SITE_PASSWORD && (
              <Pressable onPress={() => navigate('/attire')}>
                <Text style={[styles.tab, path === '/attire' && styles.active]}>
                  ATTIRE
                </Text>
              </Pressable>
            )}
            <Pressable onPress={() => navigate('/gift-ideas')}>
              <Text
                style={[styles.tab, path === '/gift-ideas' && styles.active]}
              >
                GIFT IDEAS
              </Text>
            </Pressable>
            {password === process.env.SITE_PASSWORD && (
              <Pressable onPress={() => navigate('/rsvp')}>
                <Text style={[styles.tab, path === '/rsvp' && styles.active]}>
                  RSVP
                </Text>
              </Pressable>
            )}
          </Animated.View>
        </View>
      </Animated.View>
    </View>
  );
};

HeaderBar.propTypes = {
  renderContent: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      alignSelf: 'center',
    },
    title: {
      fontFamily: 'Sanchez',
      fontSize: 60,
      color: '#515050',
      letterSpacing: 4,
    },
    subtitle: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      marginBottom: 40,
      marginHorizontal: 10,
    },
    ampersand: { width: 60, resizeMode: 'contain', marginTop: 5 },
    image: {
      position: 'absolute',
      top: 0,
      resizeMode: 'cover',
    },
    tab: {
      fontSize: 18,
      fontFamily: 'Sanchez',
      letterSpacing: 1,
      color: '#515050',
    },
    active: {
      color: '#ebae16',
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 120,
      },
      ampersand: { width: 110, resizeMode: 'contain', marginTop: 5 },
      text: {
        fontSize: 35,
        marginVertical: 10,
      },
    },
  },
);

export default HeaderBar;
