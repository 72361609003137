import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncStorage } from './useAsyncStorage';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [password, setPassword] = useAsyncStorage('password', '');
  const navigate = useNavigate();

  // call this function when you want to authenticate the password
  const login = async (data) => {
    setPassword(data);
    navigate('/home', { replace: true });
  };

  // call this function to sign out logged in password
  const logout = () => {
    setPassword('');
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      password,
      login,
      logout,
    }),
    [password],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
