import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const useAsyncStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState('');

  useEffect(async () => {
    try {
      const value = await AsyncStorage.getItem(keyName);
      if (value) {
        setStoredValue(value);
      } else {
        await AsyncStorage.setItem(keyName, defaultValue);
        setStoredValue(defaultValue);
      }
    } catch (err) {
      setStoredValue(defaultValue);
    }
  }, []);

  const setValue = async (newValue) => {
    if (
      newValue === process.env.SITE_PASSWORD ||
      newValue === process.env.ONLINE_PASSWORD
    ) {
      try {
        await AsyncStorage.setItem(keyName, newValue);
        setStoredValue(newValue);
      } catch (err) {
        setStoredValue(defaultValue);
      }
    } else {
      setStoredValue(defaultValue);
    }
  };

  return [storedValue, setValue];
};
