import { useDeviceSize } from 'rn-responsive-styles';

export const useMediaQueries = () => {
  const deviceSize = useDeviceSize();
  const isDesktop = deviceSize === 'large' || deviceSize === 'extralarge';
  const isMobile =
    deviceSize === 'medium' ||
    deviceSize === 'small' ||
    deviceSize === 'extrasmall';

  return { isDesktop, isMobile };
};
