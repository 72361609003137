import React from 'react';
import * as eva from '@eva-design/eva';
import { ApplicationProvider } from '@ui-kitten/components';
import { Navigation } from './navigation';
import Mapping from '../src/constants/Mapping';

export default function App() {
  return (
    <ApplicationProvider {...eva} customMapping={Mapping} theme={eva.light}>
      <Navigation />
    </ApplicationProvider>
  );
}
