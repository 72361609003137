import React from 'react';
import {
  Text,
  ScrollView,
  useWindowDimensions,
  View,
  Pressable,
  Image,
} from 'react-native';
import Header from '../../../assets/images/header.png';
import Ampersand from '../../../assets/images/ampersand.png';

import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import HomeIcon from '../../../assets/images/home.png';
import HomeSelectedIcon from '../../../assets/images/home_selected.png';
import StoryIcon from '../../../assets/images/story.png';
import StorySelectedIcon from '../../../assets/images/story_selected.png';
import PartyIcon from '../../../assets/images/party.png';
import PartySelectedIcon from '../../../assets/images/party_selected.png';
import AttireIcon from '../../../assets/images/attire.png';
import AttireSelectedIcon from '../../../assets/images/attire_selected.png';
import GiftIcon from '../../../assets/images/gift.png';
import GiftSelectedIcon from '../../../assets/images/gift_selected.png';
import RSVPIcon from '../../../assets/images/rsvp.png';
import RSVPSelectedIcon from '../../../assets/images/rsvp_selected.png';
import { useAuth } from '../../hooks/useAuth';

const MobileHeaderBar = ({ renderContent, path }) => {
  const { password } = useAuth();
  const navigate = useNavigate();
  const styles = useStyles();

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;

  const categories =
    password === process.env.SITE_PASSWORD
      ? [
          { url: '/home', selectedIcon: HomeSelectedIcon, icon: HomeIcon },
          {
            url: '/our-story',
            selectedIcon: StorySelectedIcon,
            icon: StoryIcon,
          },
          {
            url: '/wedding-party',
            selectedIcon: PartySelectedIcon,
            icon: PartyIcon,
          },
          {
            url: '/attire',
            selectedIcon: AttireSelectedIcon,
            icon: AttireIcon,
          },
          {
            url: '/gift-ideas',
            selectedIcon: GiftSelectedIcon,
            icon: GiftIcon,
          },
          { url: '/rsvp', selectedIcon: RSVPSelectedIcon, icon: RSVPIcon },
        ]
      : [
          { url: '/home', selectedIcon: HomeSelectedIcon, icon: HomeIcon },
          {
            url: '/our-story',
            selectedIcon: StorySelectedIcon,
            icon: StoryIcon,
          },
          {
            url: '/wedding-party',
            selectedIcon: PartySelectedIcon,
            icon: PartyIcon,
          },
          {
            url: '/gift-ideas',
            selectedIcon: GiftSelectedIcon,
            icon: GiftIcon,
          },
        ];

  const menuItem = ({ url, selectedIcon, icon }) => {
    const isActive = path === url;
    const imageSize = width / 12;

    return (
      <View
        key={url}
        style={{ flex: 1, borderTopColor: '#515050', borderTopWidth: 1 }}
      >
        <Pressable style={styles.menuItem} onPress={() => navigate(url)}>
          <Image
            style={[
              {
                width: imageSize,
                height: imageSize,
              },
            ]}
            source={{ uri: isActive ? selectedIcon : icon }}
            resizeMode="cover"
          />
        </Pressable>
        <View
          style={[
            styles.active,
            {
              opacity: isActive ? 1 : 0,
            },
          ]}
        />
      </View>
    );
  };

  const renderHomeHeader = () => {
    return (
      <>
        <View style={{ paddingTop: height / 4 }} />
        <Image
          style={[
            {
              position: 'absolute',
              top: 0,
              left: 0,
              resizeMode: 'cover',
              width,
              height: height * 1.25,
            },
          ]}
          source={{ uri: Header }}
        />
        <View style={[styles.row]}>
          <Text style={[styles.title]}>DREI</Text>
          <Image style={[styles.ampersand]} source={{ uri: Ampersand }} />
          <Text style={[styles.title]}>INRI</Text>
        </View>
        <Text style={[styles.subtitle]}>
          {password === process.env.SITE_PASSWORD
            ? 'July 2023'
            : 'Online Wedding'}
        </Text>
      </>
    );
  };

  const renderHeader = (path) => {
    let title;
    switch (path) {
      case '/our-story':
        title = 'OUR STORY';
        break;
      case '/wedding-party':
        title = 'WEDDING PARTY';
        break;
      case '/attire':
        title = 'GUEST ATTIRE';
        break;
      case '/gift-ideas':
        title = 'GIFT IDEAS';
        break;
      case '/rsvp':
        title = 'RSVP';
        break;
      default:
        title = 'DREI AND INRI';
    }

    const adjustText = title == 'WEDDING PARTY' || title == 'GUEST ATTIRE';

    return (
      <>
        <View style={{ paddingTop: width / 4 }} />
        <View style={[styles.row]}>
          <Text
            style={[
              styles.title,
              adjustText && {
                fontSize: 90,
                letterSpacing: 5,
              },
            ]}
          >
            {title}
          </Text>
        </View>
        <View style={{ paddingBottom: width / 16 }} />
      </>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View
        style={[
          styles.navbar,
          {
            width,
          },
        ]}
        pointerEvents="auto"
      >
        {categories.map((category) => menuItem(category))}
      </View>
      <ScrollView
        style={[
          {
            height,
            width,
          },
        ]}
      >
        {path === '/home' ? renderHomeHeader() : renderHeader(path)}
        <View>{renderContent()}</View>
      </ScrollView>
    </View>
  );
};

MobileHeaderBar.propTypes = {
  renderContent: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

const useStyles = CreateResponsiveStyle({
  menuItem: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 30,
    backgroundColor: '#eeeded',
  },
  title: {
    fontFamily: 'Sanchez',
    color: '#515050',
    fontSize: 110,
    textAlign: 'center',
    letterSpacing: 10,
  },
  subtitle: {
    fontFamily: 'Sanchez',
    color: '#515050',
    fontSize: 60,
    textAlign: 'center',
    flex: 1,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 100,
    zIndex: 1,
  },
  headerTitle: {
    fontFamily: 'Sanchez',
    color: '#515050',
    fontSize: 80,
  },
  image: {
    position: 'absolute',
    top: 0,
    resizeMode: 'cover',
  },
  ampersand: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
  navbar: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    opacity: 0.95,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  tab: {
    fontSize: 18,
    fontFamily: 'Sanchez',
    color: '#515050',
  },
  active: {
    flex: 1,
    borderBottomWidth: 5,
    borderBottomColor: '#ebae16',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
});

export default MobileHeaderBar;
