import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '../containers/LandingPage';
import Home from '../containers/Home';
import OurStory from '../containers/OurStory';
import WeddingParty from '../containers/WeddingParty';
import Attire from '../containers/Attire';
import RSVP from '../containers/RSVP';
import GiftIdeas from '../containers/GiftIdeas';
import { AuthProvider } from '../hooks/useAuth';
import { PrivateLayout } from './PrivateLayout';
import { PublicLayout } from './PublicLayout';

export const Navigation = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={<UnderConstruction />} /> */}
          <Route element={<PublicLayout />}>
            <Route index element={<LandingPage />} />
            <Route path="/" element={<LandingPage />} />
          </Route>
          <Route element={<PrivateLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/wedding-party" element={<WeddingParty />} />
            <Route path="/attire" element={<Attire />} />
            <Route path="/gift-ideas" element={<GiftIdeas />} />
            <Route path="/rsvp" element={<RSVP />} />
          </Route>
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};
