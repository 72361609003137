import React from 'react';
import {
  Text,
  View,
  Image,
  Linking,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import { useLocation } from 'react-router-dom';
import GCashQR from '../../../assets/images/gcash.png';
import MayaQR from '../../../assets/images/maya.png';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useMediaQueries } from '../../hooks/useMediaQueries';

const GiftIdeas = () => {
  const location = useLocation();
  const styles = useStyles();
  const width = useWindowDimensions().width;
  const { isMobile } = useMediaQueries();

  const QR_CODES = [GCashQR, MayaQR];
  const onRegistryPress = () =>
    Linking.openURL(
      'https://thesmstoregiftregistry.com/eventdetail/63c40f315ca7d02ab96c0730',
    );

  const content = () => (
    <View
      style={[
        styles.container,
        { width: isMobile ? width * 0.9 : width * 0.8 },
      ]}
    >
      {isMobile ? (
        <Text style={[styles.text, { marginTop: 40 }]}>
          Your presence at our wedding is{'\n'}
          <span
            style={{
              color: '#ebae16',
              fontFamily: 'Monsieur La Doulaise',
              fontSize: 110,
              letterSpacing: 4,
            }}
          >
            the greatest gift of all
          </span>
        </Text>
      ) : (
        <Text style={styles.title}>
          your presence at our wedding{'\n'}is the greatest gift of all
        </Text>
      )}
      <Text style={[styles.text, { marginTop: 40 }]}>
        However, if you would like to celebrate our marriage with a gift, we
        would appreciate a{' '}
        <span style={{ color: '#ebae16' }}>monetary gift</span> from you. This
        would help us establish ourselves in our new home as we enter a new
        chapter of our life. You may share your gift at the wishing well box at
        the reception or send it to us electronically (see our details below).
      </Text>
      <View style={styles.space} />
      <View style={styles.space} />
      <Text style={styles.text}>
        One of the most fulfilling things we do with our families is to share
        our blessings with those who need it more. It can be an act of giving
        food to the hungry, donating goods to the needy, or simply supporting a
        good cause. We want to continue this tradition of showing love to our
        neighbor, so we will be donating{' '}
        <span style={{ color: '#ebae16' }}>7% of the total monetary gift</span>{' '}
        we receive from you to a charity. In advance, we want to thank you for
        helping us achieve this!
      </Text>
      <View style={styles.space} />
      <View style={styles.space} />
      <View
        style={[
          {
            marginVertical: 20,
            alignItems: 'center',
            width: isMobile ? width * 0.8 : width * 0.5,
          },
          styles.subcontainer,
        ]}
      >
        <Text style={[styles.text, { marginVertical: 20 }]}>
          Account Name: {isMobile && '\n'}DANDREI RIEL T. ENRIQUEZ
        </Text>
        <View style={[!isMobile && styles.row]}>
          {QR_CODES.map((item) => {
            return (
              <Image style={[styles.image]} key={item} source={{ uri: item }} />
            );
          })}
        </View>
        <View style={{ marginVertical: 20 }}>
          <Text style={[styles.subtext, { color: '#930007' }]}>
            Bank of the Philippine Islands (BPI)
          </Text>
          <Text style={[styles.subtext, {}]}>2289-3660-35</Text>
          <View style={styles.space} />
          <Text style={[styles.subtext, { color: '#004CA3' }]}>
            Banco De Oro (BDO)
          </Text>
          <Text style={[styles.subtext, {}]}>0067-8095-4895</Text>
          <View style={styles.space} />
          <Text style={[styles.subtext, { color: '#EB6C20' }]}>Unionbank</Text>
          <Text style={[styles.subtext, {}]}>1094-8594-7822</Text>
        </View>
      </View>
      <Text style={styles.text}>
        If you prefer to give us a different kind of blessing, you can visit any
        SM Department Store&apos;s Gift Registry Section and use the event code{' '}
        <span style={{ color: '#ebae16' }}>8076867</span> or simply shop online
        using the following link:{'\n'}
        <TouchableOpacity onPress={onRegistryPress}>
          <Text style={styles.link}>
            {isMobile
              ? 'Gift Registry'
              : 'https://thesmstoregiftregistry.com/eventdetail/63c40f315ca7d02ab96c0730'}
          </Text>
        </TouchableOpacity>
        {'\n'}
        {'\n'} Thank you for staying in touch and for considering how to bless
        us on our special day! ♥
      </Text>
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
      marginBottom: 40,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 80,
      color: '#ebae16',
      marginHorizontal: 50,
      letterSpacing: 4,
      textAlign: 'center',
      lineHeight: 60,
      marginBottom: 20,
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      marginHorizontal: 50,
      textAlign: 'center',
      marginBottom: 10,
    },
    subtext: {
      fontFamily: 'Sanchez',
      fontSize: 20,
      color: '#515050',
      marginHorizontal: 50,
      marginBottom: 10,
    },
    link: {
      fontFamily: 'Sanchez',
      fontSize: 20,
      color: '#ebae16',
      marginHorizontal: 50,
      marginBottom: 10,
    },
    row: {
      flex: 1,
      width: '80%',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'no-wrap',
    },
    image: {
      height: 200,
      width: 150,
      resizeMode: 'contain',
      marginTop: 5,
    },
    space: {
      margin: 15,
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 100,
      },
      text: {
        fontSize: 35,
      },
      subtext: {
        fontSize: 40,
      },
      link: {
        fontSize: 30,
      },
      subcontainer: { width: '90%' },
      image: {
        height: 600,
        width: 450,
      },
    },
  },
);

export default GiftIdeas;
