import React from 'react';
import { Text, View, useWindowDimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import { useLocation } from 'react-router-dom';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useMediaQueries } from '../../hooks/useMediaQueries';

const OurStory = () => {
  const location = useLocation();
  const styles = useStyles();
  const { isMobile } = useMediaQueries();
  const width = useWindowDimensions().width;

  const content = () => (
    <View style={[styles.container]}>
      <Text style={styles.title}>how it started</Text>
      <Text style={styles.text}>
        Drei and Inri met at a local community youth gathering. In a game where
        participants grouped themselves according to categories, they both ended
        up in the group wearing blue and had the opportunity to introduce
        themselves to each other. Later on, Drei volunteered to serve in the
        Church through the Warmth Ministry and, surprisingly, Inri was the head
        at that time. They didn&apos;t have any casual encounters with each
        other until they were invited to a ministry member&apos;s birthday
        celebration.
      </Text>
      <Text style={styles.title}>friendship</Text>
      <Text style={styles.text}>
        The birthday was full of fun and laughter until people started sharing
        horror stories one after the other. Drei&apos;s Spider-senses were
        tingling and he noticed that Inri was uncomfortable with the setting.
        Being{' '}
        <span style={{ textDecoration: 'line-through' }}>
          uncomfortable himself
        </span>{' '}
        the friendly neighborhood person that he is, he started a different
        topic with Inri. Hours passed by and the two were sharing random stories
        about each other. Eventually, Inri felt relaxed and was touched by
        Drei&apos;s initiative, sparking their friendship. They continued to
        grow as friends, sharing jokes and meals, visiting other Feast
        communities, exploring God&apos;s Word through Bible Studies, and even
        sharing personal pains and struggles. Both of them were unaware that
        they were starting to fall for each other.
      </Text>
      <Text style={styles.title}>courtship</Text>
      <Text style={styles.text}>
        After a year of discernment, Drei mustered up the courage to ask
        permission from Inri&apos;s parents to court her. One night, Drei asked
        his dad to bring him to a flower shop, a pizza restaurant, then to
        Inri&apos;s house. His dad must have wondered and asked Drei about it.
        To his shock, Drei just blurted out,{' '}
        <span style={{ fontStyle: 'italic' }}>
          &quot;Manliligaw ako kay Inri ngayon&quot;
        </span>{' '}
        Meeting Inri&apos;s parents was nerve-wracking but Drei felt relieved
        when they gave him their permission. He brought a Stargazer flower
        symbolizing that he aims for their relationship to look towards the
        heavens and never losing hope.
      </Text>
      <Text style={styles.title}>7 means perfect</Text>
      <Text style={styles.text}>
        Inri had wanted to say &quot;Yes&quot; during the early part of their
        courtship, but she persisted in following the message revealed to her
        during meditation, which was to wait until the 7th day of the 7th month.
        At some point, Inri suggested to Drei that he should ask her every day
        of July until she finally said &apos;Yes&apos; And so, at the start of
        July 2017, Drei asked Inri to be his girlfriend, but she rejected him.
        Despite this, Drei continued to ask Inri in various and creative ways
        every day of July until Inri finally{' '}
        <span style={{ textDecoration: 'line-through' }}>gave up</span> said Yes
        on the 7th day.
      </Text>
      <Text style={styles.title}>hobbies and interests</Text>
      <Text style={styles.text}>
        The couple likes everything food-related -- cooking, trying new dishes,
        and exploring new places to eat. They also enjoy listening to IU's music
        while doing the little, ordinary things together such as grocery
        shopping, helping each other, or simply being present. As challenging as
        it may seem, the couple are proud of achieving milestones together. They
        have started their own milk tea business, acquired and renovated their
        own house, and find satisfaction in managing paperwork, paying bills,
        learning new skills, adulting, and completing the Marvel Cinematic
        Universe.
      </Text>
      <Text style={styles.title}>things you love about each other</Text>
      <Text style={styles.text}>
        Drei is attracted to Inri&apos;s sharp and beautiful eyes, but what made
        him love her even more is her dedication to her work. When she decides
        to do something, she puts her whole heart into it. Drei is inspired by
        her commitment and enjoys reciprocating by making her happier each
        passing day.
      </Text>
      <Text style={styles.text}>
        On the other hand, Inri admires Drei&apos;s passion for serving others.
        He is kind, selfless, responsible, and reliable to the people around
        him. What makes Inri fall in love with him even more is Drei&apos;s
        effort in giving his best, even on small things. Drei brings out the
        best in Inri by loving and supporting her in every decision she makes.
      </Text>
      <Text style={styles.title}>the proposal</Text>
      <Text style={styles.text}>
        During their intimate birthday trip in Zambales, Drei couldn&apos;t
        shake the feeling that it was the right time to finally ask for
        Inri&apos;s hand in marriage. All preparations were made, the view was
        magnificent, there was no one else there besides them and the resort
        owners (who are photographers too, btw). It was as if God had aligned
        the stars for this to happen. So, at sunset, Drei got down on one knee
        and popped the question. Inri teared up and asked in disbelief,
        <span style={{ fontStyle: 'italic' }}>
          &quot;Ha? Seryoso ba? Alam ba to ng parents ko?&quot;
        </span>
        . Drei happily nodded, to which Inri responded with a loving
        &quot;Yes!&quot;. After an emotional hug, they spent the night talking
        about the details and their dreams by the beach as they gazed at the
        beautiful stars that night.
      </Text>
      <View style={styles.space} />
      <View style={styles.space} />
      <Text style={styles.text}>
        It was God&apos;s Grace that brought us together. It is His grace that
        made us continually choose and love each other. And it will still be His
        grace guiding us as we embark on a new journey to forever.
      </Text>
      <View style={styles.space} />
      <Text style={styles.text}>
        On July 7, 2023, please join us in this grace-filled day, our marriage,
        to celebrate His love and goodness!
      </Text>
      <View style={styles.space} />
      <Text style={styles.text}>
        Truly, God has made everything beautiful in His time!
      </Text>
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
      marginBottom: 40,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 110,
      color: '#ebae16',
      marginHorizontal: 10,
      letterSpacing: 4,
      textAlign: 'center',
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      marginBottom: 10,
      marginHorizontal: 40,
      textAlign: 'center',
    },
    space: {
      margin: 15,
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 100,
      },
      text: {
        fontSize: 35,
      },
    },
  },
);

export default OurStory;
