import React from 'react';
import {
  Text,
  View,
  useWindowDimensions,
  Image,
  Linking,
  TouchableOpacity,
} from 'react-native';
import Proposal from '../../../assets/images/proposal.jpg';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useLocation } from 'react-router-dom';
import { useMediaQueries } from '../../hooks/useMediaQueries';
import { useAuth } from '../../hooks/useAuth';

const Home = () => {
  const { password } = useAuth();
  const location = useLocation();
  const width = useWindowDimensions().width;
  const ratio = width / 5184;
  const styles = useStyles();
  const { isMobile } = useMediaQueries();

  const onChurchPress = () =>
    // Linking.openURL("https://goo.gl/maps/fk9FfakTQ8rnJwDi6");
    Linking.openURL('https://goo.gl/maps/qn5YDQDKhYxJCM8f7');

  const onReceptionPress = () =>
    // Linking.openURL("https://goo.gl/maps/qTTEVgaJFgYkwoWa7");
    Linking.openURL('https://goo.gl/maps/mvBB1bBvkrVy7uR46');

  const onFBPress = () =>
    Linking.openURL('https://www.facebook.com/groups/798385321992185');

  const content = () => (
    <View style={[styles.container]}>
      <Image
        style={{
          width: isMobile ? width : width * 0.8,
          height: 3456 * 0.8 * ratio,
          borderColor: !isMobile ? '#ebae16' : '',
          borderWidth: !isMobile ? 2 : 0,
        }}
        source={{ uri: Proposal }}
        resizeMode="cover"
      />
      <Text style={styles.title}>it will be a grace filled day</Text>
      <Text style={styles.subtext}>We are getting married on</Text>
      <Text style={styles.subtitle}>July 7, 2023</Text>
      <Text style={styles.text}>Friday</Text>
      <Text style={styles.text}>at one o'clock in the afternoon</Text>
      <View style={styles.space} />
      {password === process.env.SITE_PASSWORD ? (
        <>
          <TouchableOpacity onPress={onChurchPress}>
            <Text style={styles.text}>
              The Transfiguration of Christ Parish Church,
              {isMobile ? '\n' : ' '}
              Antipolo, Rizal
            </Text>
          </TouchableOpacity>
          {isMobile && <View style={[styles.space, { margin: 20 }]} />}
          <TouchableOpacity onPress={onReceptionPress}>
            <Text style={styles.text}>
              Reception at Villa Ardin Events Place,{isMobile ? '\n' : ' '}
              Cainta, Rizal
            </Text>
          </TouchableOpacity>
          <View style={styles.space} />
        </>
      ) : (
        <TouchableOpacity onPress={onFBPress}>
          <Text style={styles.text}>
            Drei and Inri Online Wedding{'\n'}Facebook Group
          </Text>
        </TouchableOpacity>
      )}
      <Text style={styles.subtext}>
        {password === process.env.SITE_PASSWORD
          ? '^ Click location to navigate ^'
          : '^ Click to join ^'}
      </Text>
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
      marginBottom: 40,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 120,
      color: '#ebae16',
      marginHorizontal: 10,
      letterSpacing: 4,
      textAlign: 'center',
      flex: 1,
      alignItems: 'center',
    },
    subtext: {
      fontFamily: 'Sanchez',
      fontSize: 18,
      color: '#515050',
      marginTop: 10,
      marginBottom: 30,
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      textAlign: 'center',
      flex: 1,
      alignItems: 'center',
      marginBottom: 10,
    },
    subtitle: {
      fontFamily: 'Sanchez',
      fontSize: 48,
      color: '#515050',
      marginBottom: 10,
    },
    space: {
      margin: 15,
    },
    tab: {
      fontSize: 18,
      fontFamily: 'Sanchez',
      color: '#515050',
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 110,
      },
      text: {
        fontSize: 40,
      },
      subtext: {
        fontSize: 35,
        marginTop: 40,
      },
      subtitle: {
        fontSize: 95,
      },
      space: {
        margin: 40,
      },
    },
  },
);

export default Home;
