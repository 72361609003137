import React from 'react';
import { Text, View, ImageBackground, useWindowDimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import { useLocation } from 'react-router-dom';
import Bubble from '../../../assets/images/gray-bubble.png';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useMediaQueries } from '../../hooks/useMediaQueries';

const RSVP = () => {
  const location = useLocation();
  const styles = useStyles();
  const width = useWindowDimensions().width;
  const { isMobile } = useMediaQueries();

  const content = () => (
    <View style={[styles.container]}>
      <Text style={[styles.title, { marginBottom: 25 }]}>
        we hope you can join us!
      </Text>
      <Text style={styles.text}>We have reserved a seat for you!!</Text>
      <Text style={styles.text}>
        Please refer to your <span style={{ color: '#ebae16' }}>QR Card</span>{' '}
        for the number of seats allotted to you.
      </Text>
      <View style={styles.space} />
      <Text style={isMobile ? styles.text : styles.subtext}>
        While we love your little ones, we have decided to make our wedding an{' '}
        <span style={{ color: '#ebae16' }}>ADULT-ONLY occassion</span>. We hope
        you will see this as an opportunity to let your hair down and enjoy the
        party with us!
      </Text>
      <View style={styles.space} />
      <Text style={isMobile ? styles.text : styles.subtext}>
        We will also provide a{' '}
        <span style={{ color: '#ebae16' }}>livestream</span> of our Marriage
        Ceremony via Facebook Group so that you wouldn&apos;t miss our union
        even if you cannot join us physically.
      </Text>
      <View style={styles.space} />
      <View style={styles.divider} />
      <View
        style={[
          {
            width: width * 0.8,
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 40,
          },
          styles.subcontainer,
        ]}
      >
        <Text style={styles.text}>
          Kindly RSVP on or before June 1, 2023{'\n'}using this format and
          send/viber to{isMobile ? '\n' : ' '}
          <span style={{ color: '#ebae16' }}>0975 423 7516</span>
        </Text>
        <View style={[{ marginVertical: 20, width: '50%', textAlign: 'left' }]}>
          <Text style={styles.results}>Drei + Inri Wedding</Text>
          <Text style={styles.results}>
            Surname, Name - Response (attending or not attending)
          </Text>
          <Text style={styles.results}>
            Surname, Name - Response (attending or not attending)
          </Text>
        </View>
        <Text
          style={[
            styles.results,
            { alignSelf: 'flex-start', width: width * 0.4 },
          ]}
        >
          e.g
        </Text>
        <ImageBackground
          source={{ uri: Bubble }}
          style={[
            styles.imageBg,
            { width: isMobile ? width * 0.6 : width * 0.3 },
          ]}
          imageStyle={[styles.image]}
        >
          <View
            style={[
              {
                marginVertical: 20,
                marginLeft: isMobile ? 40 : 20,
                textAlign: 'left',
              },
            ]}
          >
            <Text
              style={[
                styles.results,
                {
                  marginBottom: 30,
                  marginLeft: 50,
                },
              ]}
            >
              Drei + Inri Wedding
            </Text>
            <Text style={[styles.results, { marginBottom: 0, marginLeft: 50 }]}>
              Penduko, Pedro - Attending
            </Text>
            <Text style={[styles.results, { marginBottom: 0, marginLeft: 50 }]}>
              Tamad, Juan - Not attending
            </Text>
          </View>
        </ImageBackground>
      </View>
      <Text style={isMobile ? styles.text : styles.subtext}>
        We appreciate your support of our intimate wedding. Invitations are
        limited to specified guests only.
      </Text>
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
      marginBottom: 40,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 80,
      color: '#ebae16',
      marginHorizontal: 10,
      letterSpacing: 4,
      textAlign: 'center',
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      marginHorizontal: 10,
      textAlign: 'center',
      marginBottom: 10,
    },
    subtext: {
      width: '50%',
      fontFamily: 'Sanchez',
      fontSize: 18,
      textAlign: 'center',
      color: '#515050',
      marginBottom: 10,
    },
    results: {
      fontFamily: 'Sanchez',
      fontSize: 18,
      color: '#515050',
      marginBottom: 10,
    },
    imageBg: {
      flex: 1,
      alignSelf: 'left',
    },
    image: {
      resizeMode: 'stretch',
    },
    divider: {
      width: '40%',
      borderColor: '#ebae16',
      borderBottomWidth: 2,
    },
    subcontainer: {},
    space: {
      margin: 15,
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 100,
      },
      text: {
        fontSize: 35,
      },
      results: {
        fontSize: 30,
        marginBottom: 30,
      },
      subtext: {
        fontSize: 30,
        width: '80%',
      },
      space: {
        margin: 40,
      },
      subcontainer: { width: '100%' },
    },
  },
);

export default RSVP;
