import React from 'react';
import {
  Text,
  View,
  SectionList,
  Image,
  useWindowDimensions,
} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import HeaderBar from '../../components/Headerbar';
import { useLocation } from 'react-router-dom';
import White1 from '../../../assets/images/white1.jpg';
import White2 from '../../../assets/images/white2.jpg';
import White3 from '../../../assets/images/white3.jpg';
import White4 from '../../../assets/images/white4.jpg';
import White5 from '../../../assets/images/white5.jpg';
import White6 from '../../../assets/images/white6.jpg';
import Gray1 from '../../../assets/images/gray1.jpg';
import Gray2 from '../../../assets/images/gray2.jpg';
import Gray3 from '../../../assets/images/gray3.jpg';
import Gray4 from '../../../assets/images/gray4.jpg';
import Gray5 from '../../../assets/images/gray5.jpg';
import Mix1 from '../../../assets/images/mix1.jpg';
import Mix2 from '../../../assets/images/mix2.jpg';
import Mix3 from '../../../assets/images/mix3.jpg';
import Mix4 from '../../../assets/images/mix4.jpg';
import Mix5 from '../../../assets/images/mix5.jpg';
import Mix6 from '../../../assets/images/mix6.jpg';
import Mix7 from '../../../assets/images/mix7.jpg';
import MobileHeaderBar from '../../components/MobileHeaderbar';
import { useMediaQueries } from '../../hooks/useMediaQueries';

const Attire = () => {
  const location = useLocation();
  const styles = useStyles();
  const width = useWindowDimensions().width;
  const { isMobile } = useMediaQueries();

  const sections = [
    {
      title: 'Full Plain White',
      data: [White1, White2, White3, White4, White5, White6],
    },
    {
      title: 'Full Light Gray',
      data: [Gray1, Gray2, Gray3, Gray4, Gray5],
    },
    {
      title: 'Combination',
      data: [Mix1, Mix2, Mix3, Mix4, Mix5, Mix6, Mix7],
    },
  ];

  const renderSectionHeader = ({ section: { title, data } }) => {
    return (
      <View style={{ alignItems: 'center', width: width }}>
        <View style={[styles.row]}>
          {data.map((item) => {
            return (
              <Image style={[styles.image]} key={item} source={{ uri: item }} />
            );
          })}
        </View>
        <Text style={[styles.text, { marginTop: 20, marginBottom: 50 }]}>
          {title}
        </Text>
      </View>
    );
  };

  const content = () => (
    <View style={[styles.container]}>
      <Text style={[styles.title, !isMobile && { marginBottom: 50 }]}>
        color palette
      </Text>
      <View style={[!isMobile && styles.row]}>
        <View style={{ alignItems: 'center', marginBottom: isMobile ? 50 : 0 }}>
          <View
            style={[
              styles.circle,
              {
                backgroundColor: '#ffffff',
              },
            ]}
          />
          <Text style={styles.text}>White</Text>
        </View>
        <View style={{ alignItems: 'center', marginBottom: isMobile ? 50 : 0 }}>
          <View
            style={[
              styles.circle,
              {
                backgroundColor: '#f6f5f4',
              },
            ]}
          />
          <Text style={styles.text}>Off/Creamy White</Text>
        </View>
        <View style={{ alignItems: 'center', marginBottom: isMobile ? 50 : 0 }}>
          <View
            style={[
              styles.circle,
              {
                backgroundColor: '#dad9d8',
              },
            ]}
          />
          <Text style={styles.text}>Light Gray</Text>
        </View>
      </View>
      <Text
        style={[
          styles.title,
          { marginVertical: 20, marginBottom: isMobile ? 0 : 20 },
        ]}
      >
        dress code
      </Text>
      <Text
        style={[
          styles.text,
          {
            marginVertical: 10,
            marginBottom: 100,
            fontSize: isMobile ? 50 : 24,
          },
        ]}
      >
        SEMI-FORMAL
      </Text>
      <SectionList
        sections={sections}
        keyExtractor={(item, index) => index}
        renderSectionHeader={renderSectionHeader}
        renderItem={() => {
          return <View />;
        }}
      />
    </View>
  );

  return isMobile ? (
    <MobileHeaderBar renderContent={content} path={location.pathname} />
  ) : (
    <HeaderBar renderContent={content} path={location.pathname} />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginHorizontal: 60,
    },
    title: {
      fontFamily: 'Monsieur La Doulaise',
      fontSize: 120,
      color: '#ebae16',
      marginHorizontal: 10,
      letterSpacing: 4,
      textAlign: 'center',
    },
    row: {
      flex: 1,
      width: '80%',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'no-wrap',
    },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      textAlign: 'center',
      marginBottom: 10,
    },
    circle: {
      borderRadius: 60,
      width: 120,
      height: 120,
      marginBottom: 20,
      shadowRadius: 5,
      shadowOffset: {
        width: 3,
        height: 4,
      },
      shadowColor: '#515050',
      shadowOpacity: 0.5,
    },
    image: {
      height: 200,
      width: 150,
      resizeMode: 'cover',
      marginTop: 5,
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 100,
      },
      text: {
        fontSize: 35,
      },
      row: {
        width: '90%',
        flexWrap: 'wrap',
      },
      circle: {
        width: 300,
        height: 300,
        borderRadius: 150,
      },
      image: {
        height: 550,
        width: 400,
        marginTop: 10,
      },
    },
  },
);

export default Attire;
