export default {
  strict: {},
  components: {
    Tab: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            textMarginVertical: 0,
            textFontSize: 18,
            textfontFamily: 'Sanchez',
            textColor: '#515050',
            state: {
              hover: {
                textColor: '#ebae16',
                textfontFamily: 'Sanchez',
              },
              selected: {
                textColor: '#ebae16',
                textfontFamily: 'Sanchez',
              },
            },
          },
        },
      },
    },
    TabBar: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            paddingVertical: 8,
            backgroundColor: 'transparent',
            indicatorBorderRadius: 0,
            indicatorBackgroundColor: 'transparent',
          },
        },
      },
    },
  },
};
