import React, { useState } from 'react';
import {
  Text,
  View,
  ImageBackground,
  useWindowDimensions,
  Image,
  Pressable,
  TextInput,
  Modal,
} from 'react-native';
import Header from '../../../assets/images/header.png';
import Ampersand from '../../../assets/images/ampersand.png';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useAuth } from '../../hooks/useAuth';

const LandingPage = () => {
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const { login } = useAuth();
  const styles = useStyles();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const checkPassword = () => {
    if (
      password === process.env.SITE_PASSWORD ||
      password === process.env.ONLINE_PASSWORD
    ) {
      login(password);
    } else {
      setError('Incorrect Password');
      setShowModal(true);
    }
  };

  return (
    <View style={(styles.container, { height, width })}>
      <ImageBackground
        source={{ uri: Header }}
        style={[styles.imageBg, { width, height: '100%' }]}
        imageStyle={[styles.image]}
      >
        <View style={[styles.titleContainer]}>
          <Text style={styles.title}>DREI</Text>
          <Image style={styles.ampersand} source={{ uri: Ampersand }} />
          <Text style={styles.title}>INRI</Text>
        </View>
        <Text style={styles.text}>Please enter event password</Text>
        <br />
        <TextInput
          value={password}
          secureTextEntry
          style={styles.formText}
          placeholder="Enter password"
          onChange={({ nativeEvent: { eventCount, target, text } }) =>
            setPassword(text)
          }
          onSubmitEditing={checkPassword}
        />
        <Pressable onPress={checkPassword} style={styles.button}>
          <Text style={styles.buttonText}>ENTER</Text>
        </Pressable>
        <Modal
          transparent
          visible={showModal}
          onRequestClose={() => {
            setShowModal(!showModal);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.modalTitle}>{error}</Text>
              <Text style={styles.modalText}>
                Please try again or contact Drei and Inri
              </Text>
              <Pressable
                style={styles.modalButton}
                onPress={() => setShowModal(!showModal)}
              >
                <Text style={styles.textStyle}>OK</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
      </ImageBackground>
    </View>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
    },
    titleContainer: {
      flexDirection: 'row',
      alignSelf: 'center',
      marginBottom: 20,
    },
    imageBg: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      resizeMode: 'cover',
    },
    title: {
      fontFamily: 'Sanchez',
      fontSize: 60,
      color: '#515050',
      marginHorizontal: 10,
      letterSpacing: 4,
    },
    ampersand: { width: 60, resizeMode: 'contain', marginTop: 5 },
    text: {
      fontFamily: 'Sanchez',
      fontSize: 24,
      color: '#515050',
      textAlign: 'center',
      marginBottom: 5,
    },
    error: {
      position: 'absolute',
      bottom: '15%',
      fontFamily: 'Sanchez',
      fontSize: 15,
      color: 'red',
      textAlign: 'center',
      marginVertical: 20,
    },
    button: {
      width: '45%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
      paddingVertical: 10,
      paddingHorizontal: 32,
      borderColor: 'black',
      borderWidth: 2,
      backgroundColor: 'white',
    },
    formText: {
      width: '45%',
      backgroundColor: '#f1f0ef',
      fontFamily: 'Sanchez',
      fontSize: 14,
      color: '#515050',
      textAlign: 'center',
      paddingVertical: 10,
    },
    buttonText: {
      fontFamily: 'Sanchez',
      fontSize: 18,
      color: '#515050',
      textAlign: 'center',
      marginVertical: -5,
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalView: {
      backgroundColor: 'white',
      paddingVertical: 35,
      paddingHorizontal: 100,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 15,
      elevation: 5,
    },
    modalButton: {
      paddingVertical: 5,
      paddingHorizontal: 50,
      elevation: 2,
      backgroundColor: '#2196F3',
    },
    textStyle: {
      fontFamily: 'Sanchez',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
    },
    modalTitle: {
      fontFamily: 'Sanchez',
      color: 'red',
      fontSize: 18,
      textAlign: 'center',
    },
    modalText: {
      fontFamily: 'Sanchez',
      color: 'red',
      marginBottom: 15,
      textAlign: 'center',
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      title: {
        fontSize: 120,
      },
      ampersand: { width: 110, resizeMode: 'contain', marginTop: 5 },
      text: {
        fontFamily: 'Sanchez',
        fontSize: 45,
        marginVertical: 10,
      },
      buttonText: {
        fontFamily: 'Sanchez',
        fontSize: 45,
      },
      button: {
        width: '70%',
      },
      formText: {
        fontFamily: 'Sanchez',
        width: '70%',
        fontSize: 45,
      },
      modalView: {
        paddingHorizontal: 50,
      },
      modalButton: {
        fontFamily: 'Sanchez',
        paddingVertical: 10,
        paddingHorizontal: 50,
        fontSize: 40,
      },
      textStyle: {
        fontFamily: 'Sanchez',
        fontWeight: 'bold',
        fontSize: 40,
      },
      modalTitle: {
        fontFamily: 'Sanchez',
        fontSize: 45,
      },
      modalText: {
        fontFamily: 'Sanchez',
        fontSize: 40,
      },
    },
  },
);

export default LandingPage;
